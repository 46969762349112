<template>
  <div class="h-screen flex flex-col md:flex-row">
    <textarea class="w-full md:w-1/3 p-4 border-gray-300 bg-gray-800 text-gray-400 resizable" style="outline: none;"
      v-model="textarea" @change="diagram()" id="editor" placeholder="Digite aqui..."></textarea>
    <div class="flex-1 p-4 bg-gray-900 overflow-y-auto relative content">
      <vue-mermaid-string :value="diagram()" :options="{ theme: 'dark', darkMode: true }" class="h-full center w-full" />
    </div>
    <div class="fixed bottom-4 right-4 flex items-center">
      <a href="https://avanso.com.br" target="_blank" class="mr-3">
        <img src="@/assets/logo.png" class="w-24">
      </a>
      <button @click="baixarPNG" class="bg-gray-500 hover:bg-gray-600 font-bold text-black text-lg py-3 px-4 rounded">
        Download PNG
      </button>
      <button @click="publicar" class="publicar ml-2 bg-yellow-500 hover:bg-yellow-600 font-bold text-black text-lg py-3 px-4 rounded">
        Publicar
      </button>
    </div>
    <!-- Opcional: inclua o link para o arquivo JavaScript do Tailwind CSS para ativar algumas funcionalidades específicas -->
    <!-- <script src="https://cdn.jsdelivr.net/npm/@tailwindcss/forms@2.2.19/dist/index.min.js"></script> -->
  </div>
</template>

<script>
import VueMermaidString from 'vue-mermaid-string'
import endent from 'endent'
//import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import logo from '@/assets/logo.png'
export default {
  name: 'App',
  components: {
    VueMermaidString,
  },
  data() {
    return {
      textarea: location.href.match("/?map=") ? atob(decodeURIComponent(location.href.split("/?map=")[1])) : "flowchart TD\n\nA([Iniciou])",
      code: null,
      map: location.href.match("/?map=") ? true : false
    }
  },
  mounted() {
    if (location.href.match("/?map=")) {
      document.querySelector('textarea').classList.add('hidden')
      document.querySelector('.publicar').classList.add('hidden')
    }
  },
  methods: {
    diagram() {
      if (this.map) {
        this.textarea = atob(decodeURIComponent(location.href.split("/?map=")[1]))
      } else {
        window.history.pushState('page2', 'Title', `/?edit=${encodeURIComponent(btoa(this.textarea))}`);
      }

      return endent`

      ${this.textarea}
      `
    },
    publicar(){
      const url = location.href.replace("/?edit=","/?map=")
      window.open(url,'_blank');
    },
    baixarPNG() {
      const elementToCapture = document.querySelector('.content');

      html2canvas(elementToCapture, { scale: 3 }).then(canvas => {
        const imgData = canvas.toDataURL('image/png', 1.0);

        // Obtém as dimensões da div .content
        const width = elementToCapture.offsetWidth;
        const height = elementToCapture.offsetHeight;

        // Cria um elemento de imagem para ajustar o tamanho da imagem
        const img = new Image();
        img.src = imgData;

        const logoavanso = logo

        img.onload = function () {
          // Cria um novo canvas para o PNG redimensionado
          const resizedCanvas = document.createElement('canvas');
          const resizedContext = resizedCanvas.getContext('2d');
          resizedCanvas.width = width * 2; // Multiplica por 2 para melhorar a resolução
          resizedCanvas.height = height * 2;
          resizedContext.drawImage(img, 0, 0, width * 2, height * 2);

          // Cria um novo canvas para adicionar a logo
          const logoCanvas = document.createElement('canvas');
          const logoContext = logoCanvas.getContext('2d');
          logoCanvas.width = width * 2;
          logoCanvas.height = height * 2;

          // Adiciona a imagem capturada ao canvas da logo
          logoContext.drawImage(img, 0, 0, width * 2, height * 2);

          // Adiciona a logo ao centro do canvas da logo com baixa opacidade
          const logo = new Image();
          logo.src = logoavanso; // Substitua pelo caminho da sua logo
          logo.onload = function () {
            const logoWidth = 200; // Largura da logo
            const logoHeight = 40; // Altura da logo
            const logoX = (width * 2 - logoWidth) / 2; // Centraliza horizontalmente
            const logoY = (height * 2 - logoHeight) / 2; // Centraliza verticalmente
            logoContext.globalAlpha = 0.5; // Opacidade da logo
            logoContext.drawImage(logo, logoX, logoY, logoWidth, logoHeight);

            // Cria um link de download para o PNG com a logo
            const a = document.createElement('a');
            a.href = logoCanvas.toDataURL('image/png', 1.0);
            a.download = `mapa.png`;
            a.click();
          };
        };
      });
    }
  }
}
</script>

<style>
/* Adicionando estilo personalizado para a alça de redimensionamento */
.resizable {
  resize: horizontal;
  /* Permite a redimensionamento apenas na horizontal */
}
</style>
